import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Link,
  Typography,
} from "@mui/material";

export interface NewsProps {
  img: string;
  title: string;
  shortDesc: string;
  longDesc?: string;
  link?: string;
}

export function News(props: NewsProps) {
  return (
    <Card
      sx={{
        my: "10px",
        width: { xs: "100%", sm: "100%", md: "100%" },
        mx: { sm: "10px", md: "10px" },
      }}
    >
      <CardActionArea>
        <CardMedia
          sx={{
            background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 25%, rgba(11,60,70,1) 75%, rgba(8,69,80,1) 93%)",
          }}        
          component="img"
          height="50px"
          alt=""
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            color={"#4b5560"}
            fontFamily="Georgia"
            textAlign={"center"}
            fontWeight="bold"
            sx={{ fontSize: { xs: "25px", md: "34px" } }}
          >
            {props.title}
          </Typography>
          <Typography
            align="center"
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: { xs: "16px", md: "22px" } }}
          >
            {props.shortDesc}
          </Typography>
          { props.link && <Link href={props.link}>{props.link}</Link> }
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
