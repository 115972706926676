import { Box, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

export default function General() {
  const headerType = {
    m: "5px",
    fontFamily: "georgia",
  };
  const bodyType = {
    m: "10px",
    fontSize: { xs: "16px", md: "20px" },
    fontFamily: "georgia",
  };
  return (
    <Container
      sx={{
        my: "10px",
        width: { xs: "100%", md: "80vw" },
      }}
    >
      <Paper elevation={0} sx={{ textAlign: "left", p: "10px" }}>
        <Typography variant="h4" sx={headerType}>
          Information
        </Typography>
        <Typography sx={bodyType}>
          I priserna ingår lån av utrustning. Vid behov erbjuder vi även lån av
          träningsbyxor och tjocktröja att spela i.
        </Typography>
        <Typography sx={bodyType}>
          Curling passar för kund/personalaktivitet, friluftsdag,
          födelsedagsfest, svensexa, möhippa eller andra händelser. Kom och
          spela hos oss. Vi har möjlighet att ta hand om upp till 60 spelande
          samtidigt på sex banor under ledning av erfarna instruktörer.
        </Typography>
        <Typography sx={bodyType}>
          I arenan finns även tillgång till dusch och bastu, konferenslokaler,
          cafeteria och stora allmänutrymmen. Vi diskuterar era önskemål när ni
          tar kontakt.
        </Typography>
        <Typography sx={bodyType}>
          För mer info kring skicka i första hand ett mail till{" "}
          <a href="mailto:info@umecurling.se">info@umecurling.se</a>. Ni kan
          också nå oss på telefon 070-5402884
        </Typography>
        <Typography variant="h4" sx={headerType}>
          Catering
        </Typography>
        <Typography sx={bodyType}>
          Vill ni äta i våra lokaler med egen catering har ni möjlighet att hyra
          vår övervåning och utrustning.
        </Typography>
      </Paper>
    </Container>
  );
}
