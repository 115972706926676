import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios, { AxiosError } from "axios";
import { AuthContext } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import { useContext, useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null); // New state for handling error messages
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:5002/login", {
        crossdomain: true,
        username,
        password,
      });
      setToken(response.data.token);
      localStorage.setItem("token", response.data.token);
      navigate("/");
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error("Authentication failed:", error);
        setToken(null);
        localStorage.removeItem("token");
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data); // Set the error message if present in the error response
        }
      }
    }
  };
  return (
    <div>

      {/* <section>
      <Button onClick={handleOpen}><p style={{marginLeft: '75%'}}>Edit contact</p><EditIcon></EditIcon></Button>
        <div className='img-div'>
        </div>
        <h2><span>Doro Onome</span></h2>
        <h2><span>nomzykush@gmail.com</span></h2>
        <h2><span>09015618845</span></h2>
      </section> */}

      <Modal
        open={open}
        onSubmit={handleClose}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Login
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Grid className='edit-container'>
                <Box>
                    <label htmlFor="">Username: </label>
                    <input type="text"/>
                </Box>
                <Box>
                    <label htmlFor="">Password: </label>
                    <input type="password"/>
                </Box>
            </Grid>
            
            <button className="edit-btn">Login</button>
          </Typography> */}
          <form onSubmit={handleSubmit}>
        <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit">Login</button>
      </form>
        </Box>
      </Modal>
    </div>
  );
}