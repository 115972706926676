import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import allaKanCurla from "../../../../img/medlem.jpeg";
import { useNavigate } from "react-router-dom";

export default function MembersCard() {
  const navigate = useNavigate();

  return (
    <Card sx={{ maxWidth: "100%", p: "2px", m: "2px", maxHeight: "100%" }}>
      <CardActionArea onClick={() => navigate("/members")}>
        <CardMedia component="img" height="100%" image={allaKanCurla} alt="" />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color={"#4b5560"}
            fontFamily="Sans-serif"
            textAlign={"center"}
            fontWeight="bold"
            fontSize={"25px"}
          >
            Medlem
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
