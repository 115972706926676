import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";

export interface OfferProps {
  img: string;
  title: string;
  description: string;
  eng?: string;
  end?: "flex-end" | "flex-start";
}

export default function Offer(props: OfferProps) {
  const styles = {
    backgroundImage: `url(${props.img})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    minHeight: "100%",
    height: "100vh",
    width: "100%",
  };

  const mobileStyles = {
    backgroundImage: `url(${props.img})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    minHeight: "50%",
    height: "30vh",
    width: "100%",
  };

  const paper = {
    width: { xs: "100%", sm: "75%", md: "50%" },
    height: "60vh",
    backgroundColor: "rgba(50,50,50, 0.95)",
    m: { xs: "0", md: "30px" },
    p: "10px",
    mt: { xs: "30px" },
    display: "flex",
    alignItems: { xs: "left", md: "center" },
  };

  const mobileType = {
    color: "#121212",
    fontSize: { xs: "30px", md: "40px" },
    fontFamily: "Georgia",
  };

  const mobileBody = {
    color: "#121212",
    fontSize: { xs: "20px", sm: "25px", md: "30px" },
    fontFamily: "Georgia",
    mx: "10px",
    marginBottom: "20px"
  };

  const type = {
    color: "#f2f2f2",
    fontSize: "60px",
    mb: "20px",
    fontFamily: "Georgia",
  };

  const body = {
    marginBottom: "20px",
    color: "#f2f2f2",
    fontSize: { xs: "20px", sm: "25px", md: "30px" },
    fontFamily: "Georgia",
  };
  
  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Grid
          container
          justifyContent={props.end}
          style={styles}
          sx={{
            alignItems: { xs: "left", md: "center" },
          }}
        >
          <Paper elevation={6} sx={paper}>
            <Stack direction={"column"}>
              <Typography variant="h2" sx={type}>
                {props.title}
              </Typography>
              <Typography align="left" sx={body}>
                {props.description}
              </Typography>
              { props.eng &&
                <Typography align="left" sx={body}>
                  {"\n"}
                  {props.eng}
                </Typography>
              }
            </Stack>
          </Paper>
        </Grid>
      </Box>
      <Box
        sx={{ my: "10px", display: { xs: "block", sm: "block", md: "none" } }}
      >
        <Stack direction={"column"}>
          <div style={mobileStyles}></div>
          <Typography variant="h2" sx={mobileType}>
            {props.title}
          </Typography>
          <Typography align="left" sx={mobileBody}>
            {props.description}
          </Typography>
          { props.eng &&
            <Typography align="left" sx={mobileBody}>
              {props.eng}
            </Typography>
          }
        </Stack>
      </Box>
    </>
  );
}
