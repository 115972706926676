import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { AppBar, styled, Typography, useTheme } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function SimpleContainer() {
  return (
    <Container maxWidth="md" sx={{ pt: "10vh" }}>
      <Box sx={{ bgcolor: "#FFFFFF", height: "65vh", overflow: "hidden",
          overflowY: "scroll" }}>
        <FullWidthTabs></FullWidthTabs>
      </Box>
    </Container>
  );
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Georgia",
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(16),
  marginRight: theme.spacing(1),
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#fff",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <AppBar sx={{}} elevation={3} position="static">
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="full width tabs example"
        >
          <StyledTab label="Umeå CK" {...a11yProps(0)} />
          <StyledTab label="Bli medlem" {...a11yProps(1)} />
          <StyledTab label="Torsdagsträning" {...a11yProps(2)} />
          <StyledTab label="Junior" {...a11yProps(3)} />
          <StyledTab label="Klubbkväll" {...a11yProps(4)} />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <UmeaInfo></UmeaInfo>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <BliMedlem></BliMedlem>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <TorsdagsTraning></TorsdagsTraning>
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <Junior></Junior>
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        <Klubbkvall></Klubbkvall>
      </TabPanel>
    </Box>
  );
}

function UmeaInfo() {
  return (
    <>
      <Typography variant="h3" sx={{ mb: "20px", fontFamily: "Georgia"}}>
        Umeå Curlingklubb
      </Typography>
      <Typography variant="body1" textAlign={"left"} sx={{fontFamily: "Georgia",
        fontWeight: 400,
        fontSize: "16px"}}>
        Umeå CK håller till i Bro & Tak arena på noliaområdet vid E12:an, en
        modern hall med 6 banor. Detta har de gjort sedan 2009. Innan dess
        spelades curlingen i Umeå på en bana i Isladan på Teg.
      </Typography>
      <Typography variant="body1" textAlign={"left"} sx={{ mt: "10px", fontFamily: "Georgia",
        fontWeight: 400,
        fontSize: "16px" }}>
        Klubben har ungefär 150 medlemmar där inkluderas en väldigt aktiv
        sektion för våra äldre medlemmar, samtidigt som en stor juniorgrupp och
        i alla åldrar däremellan.
      </Typography>
      <Typography variant="body1" textAlign={"left"} sx={{ mt: "10px", fontFamily: "Georgia",
        fontWeight: 400,
        fontSize: "16px" }}>
        Klubben tillhandahåller träning och spel för alla åldrar och förmågor.
        Vi har nybörjarträningar, allmänna medlemsträningar och förstås
        juniorträningar.
      </Typography>
    </>
  );
}

function BliMedlem() {
  return (
    <>
      <Typography variant="h3"> Bli medlem</Typography>
      <Typography variant="body1" textAlign={"left"} sx={{ mt: "10px", fontFamily: "Georgia",
        fontWeight: 400,
        fontSize: "16px" }}>
        {" "}
        Medlemsavgifter Senior: 2500 kr Förstaårsmedlem: 1250 kr Student: 1250
        kr Junior: 500 kr, t.o.m den säsong man fyller 21 år Medlemsavgiften
        betalas in på postgiro: 205511-9 Ange namn vid betalning samt skicka ett
        mail till info@umecurling.se med ditt namn, adress, personnummer,
        telefonnummer och e-postadress.
      </Typography>
    </>
  );
}

function TorsdagsTraning() {
  return (
    <>
      <Typography variant="h3"> Torsdagsträning</Typography>
      <Typography variant="body1" textAlign={"left"} sx={{ mt: "10px", fontFamily: "Georgia",
        fontWeight: 400,
        fontSize: "16px" }}>
        {" "}
        På torsdagar kl 18.30-19.45 erbjuder vi medlemmar träning under ledning
        av Johan Nygren och Sebastian Lundgren. Alla medlemmar är välkomna,
        ingen föranmälan krävs. Varje träningstillfälle har ett tema, kom gärna
        med förslag på vad just du behöver utveckla!
      </Typography>
    </>
  );
}

function Junior() {
  return (
    <>
      <Typography variant="h3"> Junior </Typography>
      <Typography
        variant="body1"
        textAlign={"left"}
        sx={{ mt: "10px", fontFamily: "Georgia",
        fontWeight: 400,
        fontSize: "16px"}}
      >
        Vi har juniorträning måndagar 18-19.30 öppen för juniorer 7-21 år. Verksamheten delas upp efter ålder och förmåga på olika banor. Vi samlas 17.45 och går på is 18.00. Vi avslutar med fika på övervåningen.

Är du sugen att prova på är det bara att dyka upp. Du är välkommen att prova tre gånger utan kostnad. Om du vill fortsätta efter det blir du medlem till en kostnad av 500 kronor/säsong. Information om betalning får du av våra tränare. 

För nybörjare och yngre barn har vi hjälmtvång. Ta med egen hjälm. Skor och sop finns att låna. Kom i varma, rörliga kläder. Våra tränare bedömer tillsammans med vårdnadshavare när det är okej att sluta med hjälm.

Vår junioransvarige heter Robert Nygren, han nås på telefonnummer 070-640 52 25 eller e-post junior@umecurling.se. Vi har fyra utbildade ungdomsledare samt engagerade föräldrar som hjälper till vilket gör att vi har möjlighet att dela upp oss i små grupper under träningarna.
      </Typography>
    </>
  );
}

function Klubbkvall() {
  return (
    <>
      <Typography variant="h3"> Klubbkväll</Typography>
      <Typography variant="body1" textAlign={"left"} sx={{ mt: "10px", fontFamily: "Georgia",
        fontWeight: 400,
        fontSize: "16px" }}>
        Varannan tisdag (udda veckor) har vi klubbkväll på curlinghallen. Då
        träffas alla medlemmar som vill och har möjlighet, lottar lag och spelar
        match. Vi brukar prova på olika spelformer. Detta är ett sätt att lära
        känna andra medlemmar i klubben och ha möjlighet att spela match även om
        man inte har något eget lag. Fokus ligger på trivsel, men det är även en
        tävling som pågår under hela säsongen. Vid varje tillfälle som man är
        med får man en poäng för deltagande. Om laget man spelar med vinner
        matchen den kvällen får man ytterligare två poäng. Om matchen blir
        oavgjord får man ytterligare en poäng. När säsongen är slut korar vi en
        vinnare, d.v.s. den som har samlat flest poäng under säsongen. Pris
        utlovas till vinnaren. Matchstart 18.30. Kom en stund innan för anmälan
        samt att vi gör i ordning isen tillsammans. Se också aktivitetskalendern
        för vilka datum det är.
      </Typography>
    </>
  );
}
