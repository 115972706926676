import { Box, Card, Grid, Paper, Stack, Typography } from "@mui/material";

import background from "../../img/bg.jpeg";
import General from "./general";
import Sponsors from "./sponsors";
import NewsSection from "./newssection";
import Offers from "./offers";

export default function LandingPage() {
  const verticalcenter = {
    textAlign: "center",
    position: "absolute",
    marginX: "5px",
    padding: "5px 10px",
    top: "40%",
    backgroundColor: "rgba(242,243,244, 0.85)",
    width: { xs: "100%", md: "100%" },
    fontSize: "35px",
    fontStyle: "italic",
    fontWeight: "medium",
    fontFamily: "Segoe UI",
  };

  const styles = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    minHeight: "100%",
    height: "100vh",
    width: "100%",
    maxWidth: "100%",
  };
  return (
    <Box>
      <div style={styles}>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Grid container justifyContent="center">
            <Card sx={verticalcenter}>
              Curling är en sport som passar alla, ung som gammal, kompisgänget
              eller familjen och som ger dig motion samtidigt som den utmanar
              ditt intellekt.
            </Card>
          </Grid>
        </Box>
      </div>
      <NewsSection></NewsSection>
      <Sponsors></Sponsors>
      <Offers></Offers>
      <General></General>
    </Box>
  );
}
