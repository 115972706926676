import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Box, Stack, Typography } from "@mui/material";
import MembersCard from "../home/body/news/smallcard";
import BookingCard from "../home/body/news/bookingcard";
import SimpleAccordion from "./accordion";

export default function BookingInfo() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md" sx={{ mt: "10px" }}>
        <Box sx={{ bgcolor: "#FAF9F6", minHeight: "100vh", height: "auto" }}>
          <Stack m="2px" direction="row">
            <Box width="75%">
              <SimpleAccordion></SimpleAccordion>
            </Box>
            <Stack direction="column" width="25%">
              <MembersCard></MembersCard>
              <BookingCard></BookingCard>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}
