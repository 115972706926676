import React from "react";
import SimpleContainer from "./container";
import background from "../../img/abst_3.jpg";

export default function MembersHomePage() {
  const styles = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    minHeight: "100%",
    height: "85vh",
    width: "100%",
    maxWidth: "100%",
  };
  return (
    <body>
      <div style={styles}>
        <SimpleContainer></SimpleContainer>
      </div>
    </body>
  );
}
