import React from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ContactUs from "./pages/contactus";
import ButtonAppBar from "./header/header";
import StickyFooter from "./footer/footer";
import BookingInfo from "./pages/booking/bookinginfo";
import MembersHomePage from "./pages/members/membershome";
import LandingPage from "./pages/landing/landing";
import ActivityCalendar from "./pages/activitycalendar/activitycalendar";
import { AuthProvider } from "./auth/AuthContext";
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import Registration from "./auth/Registration";
import Dashboard from "./auth/Dashboard";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
      <header style={{ width: "100%" }}>
        <ButtonAppBar />
      </header>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/bookinginfo" element={<BookingInfo />} />
          <Route path="/members" element={<MembersHomePage />} />      
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Registration />} />{" "}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
      
    // <body className="App">
    //   <Routes>
    
        
        
    //     {/* <Route path="/activity-calendar" element={<ActivityCalendar />} /> */}
    //   </Routes>
    //   <StickyFooter></StickyFooter>
    // </body>
  );
}

export default App;
