import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

export default function SimpleAccordion() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Företag</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            2 000 kr/ bana. 1:45 h med instruktör. Max 10 pers per bana.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Privatpersoner</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            200 kr/person. 1:45 h med instruktör. Minimipris 800 kr.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Studenter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            100 kr/ person. 1:45 h med instruktör. Minimipris 800 kr.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Juniorer (upp till 18 år)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            100 kr/ person. 1:45 h med instruktör. Minimipris 800 kr.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Rullstolscurling</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>100 kr/ person. 1:45 h med instruktör.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Studenter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            1000 kr/ 3 banor. Dagtid måndag till fredag. Max 30 elever.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box textAlign={"left"} m="5px">
        <Typography m="5px">
          I priserna ingår lån av utrustning. Vid behov erbjuder vi även lån av
          träningsbyxor och tjocktröja att spela i.
        </Typography>
        <Typography m="5px">
          Curling passar för kund/personalaktivitet, friluftsdag,
          födelsedagsfest, svensexa, möhippa eller andra händelser. Kom och
          spela hos oss. Vi har möjlighet att ta hand om upp till 60 spelande
          samtidigt på sex banor under ledning av erfarna instruktörer.
        </Typography>
        <Typography m="5px">
          I arenan finns även tillgång till dusch och bastu, konferenslokaler,
          cafeteria och stora allmänutrymmen. Vi diskuterar era önskemål när ni
          tar kontakt.
        </Typography>
        <Typography m="5px">
          För mer info kring skicka i första hand ett mail till{" "}
          <a href="mailto:info@umecurling.se">info@umecurling.se</a>. Ni kan
          också nå oss på telefon 070-5402884
        </Typography>
        <Typography variant="h4" m="5px">
          Catering
        </Typography>
        <Typography variant="body1" m="5px">
          Vill ni äta i våra lokaler med egen catering har ni möjlighet att hyra
          vår övervåning och utrustning.
        </Typography>
      </Box>
    </div>
  );
}
